import React, { useState } from "react";
import Layout from "../components/Layout";
import Modual from "../components/Modual";
import Navbar from "../components/Navbar";
// @ts-ignore
import WeddingImg from "../images/wedding.jpg";
// @ts-ignore
import WeddingGirlImg from "../images/wedding_girl.jpg";

const People = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div>
      <Layout>
        <div className="flex flex-col items-center">
          <h1 className="text-xl md:text-6xl font-mono text-black font-bold ">
            People
          </h1>
          <div className="grid grid-cols-3 gap-6 mx-64 my-16">
            <div className="album" data-modal-toggle="defaultModal">
              <img
                className="album-item"
                src={WeddingImg}
                alt="Wedding of Couple"
              />
              <span className="flex justify-center">Boda Paiz Chavez</span>
            </div>
            <div className="album" data-modal-toggle="defaultModal">
              <img
                className="album-item"
                src={WeddingGirlImg}
                alt="Wedding of Couple"
              />
              <span className="flex justify-center">Boda Paiz Chavez</span>
            </div>
            <div className="album">
              <img
                className="album-item"
                src={WeddingImg}
                alt="Wedding of Couple"
              />
              <span className="flex justify-center">Boda Paiz Chavez</span>
            </div>
            <div className="album">
              <img
                className="album-item"
                src={WeddingGirlImg}
                alt="Wedding of Couple"
              />
              <span className="flex justify-center">Boda Paiz Chavez</span>
            </div>
          </div>
        </div>
        <Modal open={openModal} close={setOpenModal} />
      </Layout>
    </div>
  );
};

const Modal = ({
  open,
  close,
}: {
  open: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div
      id="defaultModal"
      aria-hidden="true"
      className="h-full flex items-center 
      justify-center overflow-y-auto 
      overflow-x-hidden fixed top-0 right-0 
      left-0 z-50 w-full md:inset-0 h-modal 
      md:h-full"
      style={{ display: open ? "" : "none" }}
    >
      <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Terms of Service
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div className="p-6 space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              With less than a month to go before the European Union enacts new
              consumer privacy laws for its citizens, companies around the world
              are updating their terms of service agreements to comply.
            </p>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              The European Union’s General Data Protection Regulation (G.D.P.R.)
              goes into effect on May 25 and is meant to ensure a common set of
              data rights in the European Union. It requires organizations to
              notify users as soon as possible of high-risk data breaches that
              could personally affect them.
            </p>
          </div>
          <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <button
              data-modal-toggle="defaultModal"
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              I accept
            </button>
            <button
              data-modal-toggle="defaultModal"
              type="button"
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default People;
